<template>
  <div>
    <!-- 切换 -->
    <div class="tab">
      <div class="tab-title d-flex align-center">
        {{ subjectsIndex.subjectName }}
        <el-dropdown @command="handleCommand" trigger="click">
          <div class="tab-img cursor">
            <img src="../../assets/img/tab_title.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(it, idx) in subjects"
              :key="idx"
              :command="idx"
              >{{ it.subjectName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <ul class="tabs d-flex">
      <li
        class="tab-item cursor"
        @click="selectTab(0)"
        :class="{ tab_active: index == 0 }"
      >
        章节归类
      </li>
      <li
        class="tab-item cursor"
        @click="selectTab(1)"
        :class="{ tab_active: index == 1 }"
      >
        题型归类
      </li>
    </ul>
    <div class="srage" v-show="index == 0">
      <div class="table d-flex align-center">
        <div>章节</div>
        <div class="table-percent"></div>
        <div class="table-num">收藏题数</div>
        <div class="table-btn">操作</div>
      </div>
      <ul v-for="item in sectionList" :key="item.id">
        <li v-if="item.errorNume">
          <div class="first d-flex align-center cursor">
            <div class="firit-name" v-if="item.examtype == 2">每日一练</div>
            <div
              class="firit-name"
              v-if="item.examtype == 3 || item.examtype == 5"
            >
              阶段测试
            </div>
            <div class="firit-name" v-if="item.examtype == 0">历年真题</div>
            <div
              class="firit-name"
              v-if="item.examtype == 1 || item.examtype == 4"
            >
              模考真题
            </div>
            <div class="percent"></div>
            <div class="num">{{ item.errorNume }}道</div>
            <div
              class="btn d-flex align-center justify-center"
              :style="btn"
              @click="srageBtn(item.examtype)"
            >
              查看
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="sectionList == ''"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
    </div>
    <div class="srage" v-show="index == 1">
      <div class="table d-flex align-center">
        <div>题型</div>
        <div class="table-percent">收藏数量</div>
        <div class="table-num"></div>
        <div class="table-btn">操作</div>
      </div>
      <ul v-for="item in questionList" :key="item.id">
        <li>
          <div class="first d-flex align-center cursor">
            <div class="first-dian"></div>
            <div class="firit-name">{{ item.name }}</div>
            <div class="percent">{{ item.qdbQuestionNum }}道</div>
            <div class="num"></div>
            <div
              class="btn d-flex align-center justify-center"
              :style="btn"
              @click="tableBtn(item.ansInputType)"
            >
              查看
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="questionList == ''"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cutIndex: 0,
      subjects: "",
      subjectsIndex: "",
      isReady: false,
      btn: {
        backgroundImage: "url(" + require("../../assets/img/go_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      foldAll: false,
      index: 0,
      id: "",
      sectionList: "",
      questionList: ""
    };
  },
  mounted() {
    this.getClass();
  },
  methods: {
    selectTab(index) {
      this.index = index;
    },
    tabCuo(index) {
      console.log(index);
      this.cutIndex = index;
    },
    handleCommand(command) {
      this.subjectsIndex = this.subjects[command];
      this.showSection();
      this.showQuestion();
    },
    //科目列表
    async getClass(params) {
      let _this = this;
      const res = await this.$ajaxRequest('get', 'initDatatiku')
      console.log(res.data);
      _this.subjects = res.data;
      _this.subjectsIndex = res.data[0];
      this.showSection();
      this.showQuestion();
      this.isReady = true;
    },
    // getClass() {
    //   let _this = this;
    //   _this.$api
    //     .initDatatiku({})
    //     .then(res => {
    //       console.log(res.data);
    //       _this.subjects = res.data;
    //       _this.subjectsIndex = res.data[0];
    //       this.showSection();
    //       this.showQuestion();
    //       this.isReady = true;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    async showSection(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectsIndex.subjectId
      }
      const res = await this.$ajaxRequest('get', 'showSection', data)
      _this.sectionList = res.data;
    },
    // showSection() {
    //   let _this = this;
    //   _this.$api
    //     .showSection({
    //       subjectId: _this.subjectsIndex.id
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.sectionList = res.data;
    //       // _this.subjectsIndex = res.data[0];
    //       // this.getCorrect();
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
     async showQuestion(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectsIndex.subjectId
      }
      const res = await this.$ajaxRequest('get', 'showQuestion', data)
       _this.questionList = res.data;
    },
    // showQuestion() {
    //   let _this = this;
    //   _this.$api
    //     .showQuestion({
    //       subjectId: _this.subjectsIndex.id
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.questionList = res.data;
    //       // _this.subjectsIndex = res.data[0];
    //       // this.getCorrect();
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },

    srageBtn(a) {
      this.$router.push({
        path: `exam`,
        query: {
          subjectId: this.subjectsIndex.subjectId,
          examtype: a,
          source: 1
        }
      });
    },
    tableBtn(a) {
      this.$router.push({
        path: `exam`,
        query: {
          subjectId: this.subjectsIndex.id,
          ansInputType: a,
          source: 2
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
//切换
.tab {
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  background: #ffffff;
  padding: 0px 29px;
  box-sizing: border-box;
  .tab-title {
    padding: 36px 0px 22px 8px;
    font-size: 20px;
    font-weight: bold;
    color: #444444;
    box-sizing: border-box;
    img {
      width: 82px;
      height: 26px;
      margin-left: 20px;
    }
  }
}
.transform {
  transform: rotate(180deg);
}
.tab_active {
  border-color: #5f2eff !important;
  color: #333333 !important;
}
.tabs {
  margin-top: 52px;
  margin-left: 12px;
  .tab-item {
    font-size: 22px;
    color: #999999;
    padding-bottom: 9px;
    border-bottom: 3px solid transparent;
    box-sizing: border-box;
    margin-right: 37px;
  }
}
.srage {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  margin-top: 20px;
  padding: 32px 36px 43px 36px;
  box-sizing: border-box;
  min-height: 465px;
  .title {
    height: 86px;
    padding-top: 41px;
    box-sizing: border-box;
    font-size: 20px;
    color: #333333;
  }
  .table {
    height: 40px;
    background: #f7f9fa;
    padding: 0px 30px;
    box-sizing: border-box;
    div {
      font-size: 16px;
      color: #888888;
    }
    .table-percent {
      margin-left: auto;
    }
    .table-num {
      width: 100px;
      text-align: center;
      margin-left: 40px;
    }
    .table-btn {
      margin-left: 40px;
    }
  }
  .first {
    height: 76px;
    border-bottom: 1px solid #f6f9ff;
    padding-left: 30px;
    box-sizing: border-box;
    .first-dian {
      width: 6px;
      height: 6px;
      background: #5f2eff;
      border-radius: 50%;
      margin-right: 10px;
    }
    .first-img {
      width: 20px;
      height: 11px;
      margin-right: 10px;
      transition: 0.35s;
      -moz-transition: 0.35s;
      -webkit-transition: 0.35s;
      -o-transition: 0.35s;
    }
    .firit-name {
      font-size: 18px;
      color: #333333;
    }
    .percent {
      font-size: 16px;
      color: #999999;
      margin-left: auto;
      margin-right: 45px;
    }
    .num {
      font-size: 16px;
      color: #999999;
      width: 100px;
      text-align: center;
      margin-right: 16px;
    }
    .btn {
      height: 32px;
      width: 80px;
      font-size: 16px;
      color: #ffffff;
      margin-right: 7px;
    }
  }
  .second {
    height: 76px;
    border-bottom: 1px solid #f6f9ff;
    box-sizing: border-box;
    margin-left: 68px;
    .second-img {
      width: 14px;
      height: 9px;
      margin-right: 10px;
      transition: 0.35s;
      -moz-transition: 0.35s;
      -webkit-transition: 0.35s;
      -o-transition: 0.35s;
    }
    .second-name {
      font-size: 16px;
      color: #666666;
    }
    .percent {
      font-size: 16px;
      color: #999999;
      margin-left: auto;
      margin-right: 45px;
    }
    .num {
      font-size: 16px;
      color: #999999;
      width: 100px;
      text-align: center;
      margin-right: 16px;
    }
    .btn {
      height: 32px;
      width: 80px;
      font-size: 16px;
      color: #ffffff;
      margin-right: 7px;
    }
  }
  .third {
    height: 76px;
    border-bottom: 1px solid #f6f9ff;
    box-sizing: border-box;
    margin-left: 91px;
    .third-img {
      width: 6px;
      height: 6px;
      background: #5f2eff;
      border-radius: 50%;
      margin-right: 10px;
    }
    .third-name {
      font-size: 16px;
      color: #999999;
    }
    .percent {
      font-size: 16px;
      color: #999999;
      margin-left: auto;
      margin-right: 45px;
    }
    .num {
      width: 100px;
      text-align: center;
      font-size: 16px;
      color: #999999;
      margin-right: 16px;
    }
    .btn {
      height: 32px;
      width: 80px;
      font-size: 16px;
      color: #ffffff;
      margin-right: 7px;
    }
  }
  .all {
    width: 90px;
    font-size: 16px;
    color: #999999;
    margin-top: 25px;
    .all-img {
      width: 14px;
      height: 8px;
      margin-left: 8px;
      transition: 0.35s;
      -moz-transition: 0.35s;
      -webkit-transition: 0.35s;
      -o-transition: 0.35s;
    }
  }
}
</style>
